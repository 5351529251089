<template>
  <!-- 登录页背景 -->
  <div class="login-page">
    <!-- 登录框 -->
    <div class="login-frame">
      <!-- 登录框标题 -->
      <div class="login-title">云平台渠道管理系统</div>
      <!-- 账号密码输入 -->
      <div class="login-form">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          @keyup.enter="loginsubmit('form')"
        >
          <el-form-item prop="username">
            <el-input
              prefix-icon="el-icon-user"
              placeholder="用户名"
              v-model="form.username"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              prefix-icon="el-icon-lock"
              placeholder="密码"
              v-model="form.password"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              class="login-botton"
              @click="loginsubmit('form')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: "", //用户名
        password: "", //密码
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 登录
    loginsubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$axios
            .post("admin/admin/getToken", {
              username: this.form.username,
              password: this.form.password,
            })
            .then((res) => {
              //登陆成功
              localStorage.setItem("userInfo", JSON.stringify(res.data));
              this.$router.push("/");
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scope>
.login-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(../../assets/img/login.jpg);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-frame {
  width: 350px;
  height: 300px;
  background-color: rgb(255, 255, 255, 0.7);
  margin: 160px auto;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #808080 5px 5px 10px #808080;
}
.login-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
  font-size: 26px;
  border-bottom: 2px solid #dddddd;
  color: #0289f5;
  font-weight: bold;
  letter-spacing: 2px;
}

.login-form {
  padding: 30px;
}
.login-botton {
  width: 100%;
  /* background-color: #0289f5; */
}

/* 输入框用户图标样式修改 */
.el-icon-user:before {
  font-size: 25px;
  color: #0289f5;
}
/* 输入框密码图标样式修改 */
.el-icon-lock:before {
  font-size: 25px;
  color: #0289f5;
}
/* 输入框左间隔 */
.el-input--prefix .el-input__inner {
  padding-left: 40px !important;
}
</style>